import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { State } from '../reducers';
import { markAsLoaded, markAsNavigated, setSignupStatus } from './root.actions';
import {
    queryHasNavigated,
    queryRootLoading,
    queryRootSignupStatus,
    SignupStatus
} from './root.reducer';

@Injectable()
export class RootFacade {
    loaded$ = this.store.select(queryRootLoading).pipe(
        filter((loaded) => !!loaded),
        take(1)
    );
    signupStatus$ = this.store.select(queryRootSignupStatus);
    hasNavigated$ = this.store.select(queryHasNavigated);

    constructor(private store: Store<State>) {}

    markAsLoaded() {
        this.store.dispatch(markAsLoaded());
    }
    markAsNavigated() {
        this.store.dispatch(markAsNavigated());
    }

    setSignupStatus(status: SignupStatus) {
        this.store.dispatch(setSignupStatus({ status }));
    }

    /**
     * @depricated
     */
    // setSignupAsPending() {
    //     this.store.dispatch(signupPending());
    // }
}
