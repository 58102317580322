import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import * as RootActions from './root.actions';

export const FEATURE_KEY = 'root';

export enum SignupStatus {
    UNKNOWN = 'unknown',
    COMPLETED = 'completed',
    PENDING = 'pending',
    STARTED = 'started',
    PROMPTED = 'prompted',
    NOT_STARTED = 'not_started'
}

export interface State {
    loaded: boolean;
    hasNavigated: boolean;
    ageVerified: boolean;
    cookiesVerified: boolean;
    signupStatus: SignupStatus;

    signupPending: boolean;
}

export const initialState: State = {
    loaded: false,
    hasNavigated: false,
    ageVerified: false,
    cookiesVerified: false,
    signupStatus: SignupStatus.UNKNOWN,

    signupPending: false
};

const rootReducer = createReducer(
    initialState,

    on(RootActions.markAsLoaded, (state) => ({
        ...state,
        loaded: true
    })),

    on(RootActions.verifyAgeSuccess, (state) => ({
        ...state,
        ageVerified: true
    })),
    on(RootActions.verifyCookiesSuccess, (state) => ({
        ...state,
        cookiesVerified: true
    })),

    on(RootActions.markAsNavigated, (state) => ({
        ...state,
        hasNavigated: true
    })),

    on(RootActions.setSignupStatus, (state, action) => {
        const signupStatus = Object.values(SignupStatus).includes(action.status)
            ? (action.status as SignupStatus)
            : SignupStatus.NOT_STARTED;

        return {
            ...state,
            signupStatus
        };
    }),

    on(RootActions.signupPending, (state) => ({
        ...state,
        signupPending: true
    }))
);

export function reducer(state: State | undefined, action: Action) {
    return rootReducer(state, action);
}

const queryRoot = createFeatureSelector<State>(FEATURE_KEY);

export const queryRootLoading = createSelector(queryRoot, (state) => state.loaded);

export const queryHasNavigated = createSelector(queryRoot, (state) => state.hasNavigated);

export const queryRootSignupStatus = createSelector(queryRoot, (state) => state.signupStatus);

export const queryRootSignupPending = createSelector(queryRoot, (state) => state.signupPending);
