import { createAction, props } from '@ngrx/store';

import { SignupStatus } from './root.reducer';

export const loading = createAction('[App/Root] Change Loading State', props<{ state: boolean }>());

export const markAsLoaded = createAction('[App/Root] Mark as loaded');
export const markAsNavigated = createAction('[App/Root] Mark as navigated');

export const verifyAge = createAction('[App/Root] Verify age');
export const verifyAgeSuccess = createAction('[App/Root] Verify age success');

export const verifyCookies = createAction('[App/Root] Verify cookies');
export const verifyCookiesSuccess = createAction('[App/Root] Verify cookies success');

export const setSignupStatus = createAction(
    '[App/Root] Set signup status',
    props<{ status: SignupStatus; silent?: boolean }>()
);

export const signupPending = createAction('[App/Root] Signup pending');
